import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import { graphql } from "gatsby";
import HikakuTable from "components/hikakuTableLoadable";
import AccentBox from "components/accentBox";
import Image from "components/image";
import Socials from "components/socials";
import PassMark from 'components/cpu/passmark';
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "cpu/ryzen"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/cpu/ryzen.png"}) { ...eyecatchImg },
    allRyzenJson {
      nodes {
        name
        pm
        pms
        year
        clock
        core
        thread
        tdp
        price
        cospa
      }
    },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Ryzen比較表 | 世代や種類別にRyzenのCPUを比較`}</h1>
    <time itemProp="datePublished" dateTime="Sat Nov 16 2024 00:00:25 GMT+0900 (日本標準時)">更新日:2024年11月16日</time>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="Ryzen比較表" mdxType="Image" />
    <p>{`Ryzenは2017年に発売を開始したAMDのCPUである。当初からコスパや性能が高く話題となっていたが、第３世代以降に弱点だったゲーム性能もインテルと比べて遜色のないものとなりデスクトップパソコンで自作をするならRyzenという機運も高まっている。`}</p>
    <p>{`ところがRyzenもインテルと同様に数多くのモデルが発表されており、同じRyzenならば何が性能が高いのか、コストパフォーマンスが高いのかなど、どれがいいのか比較が難しい。`}</p>
    <p>{`そこで本ページでは複数のベンチマークでRyzen CPUを比較しその性能を明らかにする。`}</p>
    <p>{`Ryzen以外のCPUを探したい場合やCore iシリーズと比較したい場合は`}<a href="/cpu/">{`CPU性能比較表`}</a>{`を参考にして欲しい。`}</p>
    <h2 {...{
      "id": "Ryzenの型番からある程度性能を読み取る",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Ryzen%E3%81%AE%E5%9E%8B%E7%95%AA%E3%81%8B%E3%82%89%E3%81%82%E3%82%8B%E7%A8%8B%E5%BA%A6%E6%80%A7%E8%83%BD%E3%82%92%E8%AA%AD%E3%81%BF%E5%8F%96%E3%82%8B",
        "aria-label": "Ryzenの型番からある程度性能を読み取る permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Ryzenの型番からある程度性能を読み取る`}</h2>
    <p>{`比較表に入る前の前提知識として型番の解説をおこなっておく。型番を理解すると大まかなCPU性能が推測でき、比較が容易になる。ここではRyzen 9 7950Xを例として挙げて解説する。`}</p>
    <p>{`まずは最初の9に関して、Ryzenは`}<strong parentName="p">{`Ryzen 9, Ryzen 7, Ryzen 5, Ryzen 3とシリーズが分かれており、数値が大きいほど性能が高い`}</strong>{`。ちょうどIntelがCore Ultra 9, Core Ultra 7, Core Ultra 5というシリーズを出しているので同じようなものと考えてもらえれば分かりやすい。`}</p>
    <p>{`次に`}<strong parentName="p">{`9950Xの最初の9は世代`}</strong>{`を表す。世代はCPUのアーキテクチャが現在何番目であるかを示す値である。通例１年ごとに更新され、`}<strong parentName="p">{`世代が上のものほど設計が洗練されパフォーマンスが高い`}</strong>{`。`}</p>
    <p><strong parentName="p">{`950の部分はマイナーバージョンを表す数値`}</strong>{`である。アーキテクチャ自体に差異はないが、この数値が大きいほど性能が高い。`}</p>
    <p>{`最後にXはExtremeを表す文字である。末尾の文字が意味するところは様々であり、Uは低消費電力、GはGraphic機能ありといった意味がある。一覧を表にまとめる。`}</p>
    <AccentBox title="AMD CPUの末尾文字" mdxType="AccentBox">
  <li><span className="bold">アルファベット無し</span>・・・通常版</li>
  <li><span className="bold">X</span>・・・AMDの最高峰CPUを冠した文字、Intelと同様</li>
  <li><span className="bold">G</span>・・・GPU内臓(AMDのCPUはG以外だとグラフィックボードが必須)</li>
  <li><span className="bold">U</span>・・・ノート用低消費電力版、Intelと同様</li>
  <li><span className="bold">E</span>・・・ノート用低消費電力版</li>
  <li><span className="bold">H/HS</span>・・・ノート用ハイパフォーマンス版</li>
    </AccentBox>
    <p>{`このうち`}<strong parentName="p">{`通常版とXはGPU機能が搭載されず、グラフィックボードの購入が必須となる`}</strong>{`ので気を付ける必要がある。`}</p>
    <p>{`またRyzen PRO...とPROがついているモデルがあるが、`}<strong parentName="p">{`PROモデルは企業向けにセキュリティ機能を強化したバージョン`}</strong>{`である。例えばカンファレンスやミーティングで盗まれたパソコンからハッキングにより情報が引き抜かれるのを防いでくれる。CPU性能自体には差は出ないと思ってもらってよい。`}</p>
    <p>{`最後にいくつか参考として例を載せておく。これで型番の読み方はばっちりであろう。`}</p>
    <AccentBox title="Ryzen CPUの型番" mdxType="AccentBox">
  <li><span className="bold">Ryzen 5 8500G</span>・・・第8世代でGPU搭載版</li>
  <li><span className="bold">Ryzen 9 9950X</span>・・・第9世代で最高グレードのCPU</li>
  <li><span className="bold">Ryzen 5 5500</span> ・・・第5世代、デスクトップ向け通常版</li>
  <li><span className="bold">Ryzen 7 6800U</span> ・・・第6世代、ノート用低消費電力版</li>
    </AccentBox>
    <p>{`ちなみに例えばRyzen 7000シリーズはZen 4というアーキテクチャなので厳密には第４世代であると説明されることもあるが、細かいので気にしなくても良いだろう。`}</p>
    <p>{`また、ノートPC向けにAIを強化した`}<em parentName="p">{`Ryzen AI`}</em>{`というシリーズも開発されており、例えばRyzen AI 9 365というCPUがあるが、Ryzenの第３世代ではなくRyzen AIの第３世代ということに注意したい。(そして何故か第３世代が最も若い)`}</p>
    <h2 {...{
      "id": "Ryzen-比較表",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Ryzen-%E6%AF%94%E8%BC%83%E8%A1%A8",
        "aria-label": "Ryzen 比較表 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Ryzen 比較表`}</h2>
    <p>{`Ryzen CPUの一覧を示す。この中から自分にあったモデルを探して欲しい。ベンチマークの意味とその他表の見方は以下を参考。`}</p>
    <p>{`またPASSMARKの目安を下記に示す。スコアの参考としてほしい。他のベンチマークと比べてPASSMARKは`}<strong parentName="p">{`CPUのテストサンプル数が多く、統計的に安定している`}</strong>{`のでRyzen同士の比較を行う時は、他の情報を参考にしつつもPASSMARKを中心に見ると良い。`}</p>
    <PassMark mdxType="PassMark" />
    <p>{`また、上記総合スコアである`}<em parentName="p">{`PassMark(M)`}</em>{`(Mはマルチコアの略)の他、シングルコアスコアである`}<em parentName="p">{`PassMark(S)`}</em>{`も併記する。`}</p>
    <p>{`シングルコア性能はアプリの起動やWebブラウジングのサクサク感、ゲームなどに効力を発揮し、マルチコア性能が高いと複数アプリの同時実行、ファイルの解凍・圧縮、画像のレンダリングやAI処理などに特に効果を発揮する。`}</p>
    <HikakuTable {...props} checkboxes={['ryzen', 'notedesk']} cpuData={props.data.allRyzenJson.nodes} mdxType="HikakuTable" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      